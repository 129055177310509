@import 'styles/nprogress.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.mysawit-app .anticon {
    vertical-align: 2px !important;
}

.mysawit-app .ant-image-preview-img {
    display: initial;
}

@layer base {
    body {
        @apply text-sm font-noto;
        font-weight: 400;
    }

    input {
        @apply focus:outline-none;
    }

    button, a {
        @apply active:outline-none focus:outline-none
    }

    * {
        @apply active:outline-none focus:outline-none
    }
}

@layer components {
    .bg-color-theme{
        @apply bg-amber-500
    }
    .hover\:bg-color-theme{
        @apply hover:bg-amber-500
    }

    .text-color-theme{
        @apply text-amber-500
    }
    .hover\:text-color-theme{
        @apply hover:text-amber-500
    }

    .border-color-theme{
        @apply border-amber-500
    }
    .focus\:border-color-theme{
        @apply focus:border-amber-500
    }
    .hover\:border-color-theme{
        @apply hover:border-amber-500
    }

    nav.my-sidebar ul .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title, nav.my-sidebar .ant-menu-inline > .ant-menu-item {
        height: 40px;
        font-weight: 500;
        /* font-size: 15px; */
    }
    nav.my-sidebar ul .ant-menu-item:not(:last-child), nav.my-sidebar .ant-menu-item, nav.my-sidebar .ant-menu-submenu-title{
        margin-bottom:0;
        margin-top:0;
    }

    .no-ant-form-item-margin .ant-form-item{
        @apply m-0 !important;
    }
}